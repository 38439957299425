import React, { useState } from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { SUBMIT_BETA_FORM } from './queries'
import Input from 'components/input'
import Textarea from 'components/textarea'
import SubmitButton from 'components/buttons/submit-button'

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().phone('US', false, 'Invalid phone').required('Required'),
  message: Yup.string().required(),
})


const ConsultancyForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [submitBetaForm, { loading }] = useMutation(SUBMIT_BETA_FORM,
    {
      onError: (e) => console.log('form submit failed', e),
      onCompleted: () => {
        setSubmitted(true);
      }
    },
  );


  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);

    submitBetaForm({variables: {
        input: values
      }
    })

    actions.setSubmitting(false);
  }

  if (submitted) {
    return (
      <div className="mt-12 flex justify-center">
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Thank You!
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500">
                  We've got your message and will get in touch shortly!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-12">
      <Formik
        initialValues={{
          name: '',
          company: '',
          email: '',
          phone: '',
          title: '',
          message: '',
        }}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
      {({ errors, touched, values, isValid, isSubmitting }) => (
      <Form className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">

        <div className="sm:col-span-2 mt-4">
          <Input name='name'/>
        </div>
        <div className="sm:col-span-2 mt-4">
          <Input name='email' type='email' />
        </div>
        <div className="sm:col-span-2 mt-4">
          <Input name='phone' />
        </div>
        <div className="sm:col-span-2 mt-4">
          <Input name='company'/>
        </div>
        <div className="sm:col-span-2 mt-4">
          <Textarea name='message' label='Where did you hear about Supergrid?' />
        </div>
        <div className="sm:col-span-2 mt-4">
          <SubmitButton copy="Enroll in the Beta" isSubmitting={isSubmitting} />
        </div>
      </Form>
      )}
      </Formik>
    </div>
  )

}

export default ConsultancyForm;
