import React, { useState } from 'react';
import GitHubLogin from 'components/github-login';
import AuthIconButton from 'components/buttons/auth-icon-button'
import Error from 'components/typography/error'
import { ReactComponent as GithubLogo } from 'assets/svgs/github-logo.svg';
import { LOGIN_WITH_GITHUB_MUTATION } from './queries';
import { useMutation } from '@apollo/react-hooks';
const { REACT_APP_GITHUB_CLIENT_ID } = process.env;

const GithubLoginButton = ({
  clientId,
  scope,
  onSuccess,
}) => {
  const [githubError, setGithubError] = useState(null);

  const onFailure = (response) => {
    console.log('Github Failed', response)
    setGithubError('Something went wrong, please try again.')
  }

  const [loginBackendWithGithub, { loading }] = useMutation(LOGIN_WITH_GITHUB_MUTATION,
    {
      onError: onFailure,
      onCompleted: ({ loginWithGithub }) => onSuccess(loginWithGithub.user)
    },
  );

  const onOauthSuccess = async (input) => {
    await loginBackendWithGithub({
      variables: {
        input
      },
    });
  }

  return (
    <div className="flex-col">
      <span className="w-full mb-2 inline-flex rounded-md shadow-sm">
        <GitHubLogin
          className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out'
          clientId={REACT_APP_GITHUB_CLIENT_ID}
          scope='user:email read:user'
          onSuccess={onOauthSuccess}
          onFailure={onFailure}
        >
          <GithubLogo className="w-5 h-5 mr-3" />
          <span className="text-gray-700">{loading ? 'Loading...' : 'Sign in with Github'}</span>
        </GitHubLogin>
      </span>
      {githubError && <Error center>{githubError}</Error>}
    </div>
  )
}



export default GithubLoginButton
