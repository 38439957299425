import React from 'react';
import WaitlistForm from './form';
import { Link } from 'react-router-dom';
import Logo from 'assets/svgs/supergrid-words-stacked-black.svg';
import H2 from 'components/typography/h2'
import H5 from 'components/typography/h5'
import P from 'components/typography/p'


const Login = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/" aria-label="Home">
          <img className="mx-auto h-20 w-auto" src={Logo} alt="Supergrid logo" />
        </Link>
      </div>
      <div className="mt-8 justify-center flex w-full ">
        <div className="bg-white py-8 w-full max-w-3xl px-4 shadow sm:rounded-lg sm:px-10">
          <H2 center classes='mt-1'>Beta Signup</H2>
          <H5 center classes='mt-1 text-teal-400'>Sign up for early access to our Beta program!</H5>
          <P center classes='mt-5'>We are onboarding customers one at a time to ensure our platform is scalable and robust.</P>
          <P center classes='mt-1'>Submit the form below and we will reach out to you shortly via email.</P>
          <WaitlistForm />
        </div>
      </div>
      <div>
        <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
          Already have an account?&nbsp;
          <Link to="/login" className="font-medium text-teal-400 hover:text-teal-300 focus:outline-none focus:underline transition ease-in-out duration-150">
            Sign in instead
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
