import gql from 'graphql-tag';

export const GITHUB_INFO_MUTATION = gql`
  mutation GithubInfo($input: GithubInfoInput!) {
    githubInfo(input: $input) {
      githubId
      name
      firstName
      lastName
      email
      username
      imageUrl
      existingUser
    }
  }
`;
