import React, { useState } from 'react';
import GitHubLogin from 'components/github-login';
import Error from 'components/typography/error'
import { ReactComponent as GithubLogo } from 'assets/svgs/github-logo.svg';
import { GITHUB_INFO_MUTATION } from './queries';
import { useMutation } from '@apollo/react-hooks';
const { REACT_APP_GITHUB_CLIENT_ID } = process.env;


const GithubAuthButton = ({ onSuccess }) => {
  const [githubError, setGithubError] = useState(null);

  const onFailure = (response) => {
    console.log('Github Failed', response)
    setGithubError('Something went wrong, please try again.')
  }

  const onGithubInfoSuccess = ({ githubInfo }) => {

    if (githubInfo.existingUser) {
      setGithubError('That user already exists, please log in instead.')
    } else {
      onSuccess({
        name: githubInfo.name,
        firstName: githubInfo.firstName,
        lastName: githubInfo.lastName,
        email: githubInfo.email,
        imageUrl: githubInfo.imageUrl,
        username: githubInfo.username,
        providerId: githubInfo.githubId,
        provider: 'github',
      })
    }
  }

  const [githubInfoMutation, { loading }] = useMutation(GITHUB_INFO_MUTATION,
    {
      onError: onFailure,
      onCompleted: onGithubInfoSuccess
    },
  );

  const onOauthSuccess = (input) => {
    githubInfoMutation({
      variables: {
        input
      }
    })
  }

  return (
    <div>
      <span className="w-full flex-col rounded-md shadow-sm">
        <GitHubLogin
          className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out'
          clientId={REACT_APP_GITHUB_CLIENT_ID}
          scope='user:email read:user'
          onSuccess={onOauthSuccess}
          onFailure={onFailure}
        >
          <GithubLogo className="w-5 h-5 mr-3" />
          <span className="text-gray-700">{loading ? 'Loading...' : 'Sign Up with Github'}</span>
        </GitHubLogin>
        {githubError && <Error classes='mt-2' center>{githubError}</Error>}
      </span>
    </div>
  )
}



export default GithubAuthButton
