import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SUBMIT_WAITLIST_FORM } from './queries'

const WaitlistSignup = () => {
  const inputRef = useRef()
  const [submitted, setSubmitted] = useState(false);
  const [submitWaitlistForm, { loading }] = useMutation(SUBMIT_WAITLIST_FORM,
    {
      onError: (e) => {
        console.log('form submit failed', e)
        setSubmitted(true);
      },
      onCompleted: () => {
        setSubmitted(true);
      }
    },
  );

  const onSubmit = (e) => {
    e.preventDefault()
    submitWaitlistForm({variables: { input: { email: inputRef.current.value } } })
  }

  if (submitted) {
    return <p className='mt-12 text-center text-white'>Thanks! We'll get in touch soon.</p>
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='flex md:flex-row flex-col align-top px-8 md:px-0 mt-10 w-100 justify-center'>
        <input ref={inputRef} inputMode="email" type="text" autoCapitalize="off" className="focus:ring-white-200 focus:border-white-200 text-white bg-gray-900 block md:w-64 w-full h-12 sm:text-xl font-light border-2 border-indigo-400 px-4 py-2" placeholder="you@example.com" aria-describedby="email-optional" />
        <button type='submit' className="inline-flex items-center justify-center md:ml-2 md:mt-0 mt-2 px-5 py-3 border h-12 border-transparent text-base leading-6 font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
          Join the Waitlist
        </button>
      </div>
    </form>
  )


}

export default WaitlistSignup
