import React from 'react';
import LogoFooter from 'assets/svgs/supergrid-words-gray-500.svg';
import IconCTA from 'assets/svgs/supergrid-icon-white.svg';
import WaitlistSignup from 'marketing/components/waitlist-signup';

const BottomCTA = () => {
  return (
    <div className="relative pt-12 pb-6 bg-gray-900">
      <div className="mt-20 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-28">
        <div className="text-center">
          <img alt='supergrid logo' src={IconCTA} className='w-16 mx-auto mb-10' />
          <h2 className="text-4xl tracking-tight leading-10 font-bold text-white sm:leading-none lg:text-6xl">
            Get On The Supergrid Waitlist
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base font-light tracking-wide text-gray-400 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Join our private alpha and be the first to access the power of Supergrid.
          </p>

          <WaitlistSignup />
        </div>
      </div>
      <div className="border-t border-gray-800 mt-32 flex-col flex justify-center items-center border-gray-200 pt-8">
        <img alt='supergrid logo' src={LogoFooter} className='w-40' />
        <p className="mt-4 text-center text-gray-500 font-semibold leading-6">
          Middleware for the world. Made in NYC.
        </p>

        <p className="text-base leading-6 text-gray-600 font-base xl:text-center">
          &copy; 2020 Deus Technologies, Inc. All rights reserved.&nbsp;
        </p>
        <p className="text-base leading-6 text-gray-600 font-base xl:text-center">
          <a href='/terms.html' target='_blank' className='text-decoration: underline'>Terms</a>&nbsp;&bull;&nbsp;
          <a href='/privacy.html' target='_blank' className='text-decoration: underline'>Privacy</a>
        </p>
      </div>
    </div>
  )
}

export default BottomCTA
