import gql from 'graphql-tag';

export const LOGIN_WITH_GOOGLE_MUTATION = gql`
  mutation LoginWithGoogle($input: LoginWithGoogleInput!) {
    loginWithGoogle(input: $input) {
      user {
        firstName
        lastName
        fullName
        authToken
        email
        username
        id
      }
    }
  }
`;
