import React from 'react';
import { useField, ErrorMessage } from 'formik';

const Input = ({
  name,
  noLabel,
  label,
  type = 'text',
  placeholder
 }) => {
  const [field, { touched, error }, { setValue, setTouched, setError }] = useField(name);

  console.log("Field", field, touched, error)

  const onChange = (e) => {
    setTouched()
    setValue(e.currentTarget.value)
  }

  return (
    <>
      {!noLabel && <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700 capitalize">{label || name}</label>}
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          className="form-input py-3 px-4 block border w-full transition ease-in-out duration-150" />
      </div>
        {touched && error ? (
          <div className="capitalize text-sm pt-1 pl-1 text-red-700">{error}</div>
        ) : null}
    </>
  )

}

export default Input
