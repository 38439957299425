import React from 'react';

import AlwaysBackwardsCompatible from 'assets/svgs/always-backwards-compatible.svg';
import DesktopApp from 'assets/svgs/desktop-app.svg';
import EveryFormula from 'assets/svgs/every-formula.svg';
import ReengineeredForSpeed from 'assets/svgs/reengineered-for-speed.svg';

const Replacement = () => {
  return (
    <div className="bg-gradient-to-br from-blue-800 py-20 via-green-700 to-indigo-900 overflow-hidden flex flex-col items-center">
      <div className="relative md:max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative text-center">
          <div className='md:bg-transparent md:rounded-none md:shadow-none py-3 md:py-0 bg-indigo-700 rounded-md shadow-lg mx-auto text-center'>
            <span className='md:bg-indigo-700 md:rounded-md md:shadow-lg md:mx-auto text-white font-bold md:px-4 md:py-2 text-center text-xs tracking-widest uppercase'>OPEN ANY EXISTING EXCEL FILE AND&nbsp;UPGRADE&nbsp;YOUR&nbsp;WORKFLOW</span>
          </div>

          <h3 className="text-center text-3xl mt-8 leading-8 font-extrabold tracking-tight text-white sm:text-5xl sm:leading-10">
            A Drop-In Replacement&nbsp;for&nbsp;Excel
          </h3>
          <p className="my-4 mx-auto text-center text-xl font-light leading-7 text-gray-300">
            Supergrid combines the power of Excel with the collaborative features of Google Sheets.
          </p>
          <p className="my-4 mx-auto text-center text-xl font-light leading-7 text-gray-300">
            Plus it's faster and easier to use. And you can export to .xlsx at any time.
          </p>
          <video className='w-full mt-20 shadow-lg rounded-md' style={{width: "100%"}} loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/drag.mp4">
          	<source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/drag.mp4" />
          </video>
        </div>


      </div>
      <div className='flex max-w-screen-2xl flex-col items-center justify-center'>
        <ul className='grid grid-cols-1 grid-rows-4 xl:grid-cols-4 xl:grid-rows-1 mt-32 gap-8 md:mt-32 mb-32 w-full'>
          <li className='md:px-4 md:pb-4 pt-8 px-4 pb-3 bg-white shadow-xl rounded-md w-80 h-80 flex flex-col items-center'>
            <img src={ReengineeredForSpeed} className='h-16 mb-8' />
            <h5 className='text-black mb-4 text-lg tracking-tight font-bold'>Re-engineered For Speed</h5>
            <p className='text-center text-gray-600 text-base font-light leading-5'>We’ve separated computation and visualization so you can run complex formulas on huge data sets without freezing for even a millisecond.</p>
          </li>
          <li className='md:px-4 md:pb-4 pt-8 px-4 pb-3 bg-white shadow-xl rounded-md w-80 h-80 flex flex-col items-center'>
            <img src={AlwaysBackwardsCompatible} className='h-16 mb-8' />
            <h5 className='text-black mb-4 text-lg tracking-tight font-bold'>Always. Backwards. Compatible.</h5>
            <p className='text-center text-gray-600 text-base font-light leading-5'>Export to a full-featured Excel workbook with formulas intact when you don’t need Supergrid’s collaborative features.</p>
          </li>
          <li className='md:px-4 md:pb-4 pt-8 px-4 pb-3 bg-white shadow-xl rounded-md w-80 h-80 flex flex-col items-center'>
            <img src={DesktopApp} className='h-12 mb-12' />
            <h5 className='text-black mb-4 text-lg tracking-tight font-bold'>Offline-First Desktop App</h5>
            <p className='text-center text-gray-600 text-base font-light leading-5'>Supergrid’s high-performance desktop app works seamlessly offline and automatically reconnects to share files and collaborate.</p>
          </li>
          <li className='md:px-4 md:pb-4 pt-8 px-4 pb-3 bg-white shadow-xl rounded-md w-80 h-80 flex flex-col items-center'>
            <img src={EveryFormula} className='w-16 mb-5' />
            <h5 className='text-black mb-4 text-lg tracking-tight font-bold'>The Formulas You Already Know</h5>
            <p className='text-center text-gray-600 text-base font-light leading-5'>All of the formulas you’re used to using in other spreadsheets work in Supergrid out of the box.</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Replacement
