import gql from 'graphql-tag';

export const GET_USERNAME_AVAILABILITY = gql`
  query GetUsernameAvailability($username: String!) {
    usernameAvailability(username: $username)
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      user {
        firstName
        lastName
        fullName
        authToken
        email
        username
      }
    }
  }
`;
