import React from 'react';

import Nav from 'marketing/components/nav'
import Footer from 'marketing/components/footer'
import {ReactComponent as GridPatternBASE} from 'assets/svgs/hero-pattern-base.svg';
import {ReactComponent as GridPatternSM} from 'assets/svgs/hero-pattern-sm.svg';
import {ReactComponent as GridPatternMD} from 'assets/svgs/hero-pattern-md.svg';
import {ReactComponent as GridPatternLG} from 'assets/svgs/hero-pattern-lg.svg';
import {ReactComponent as GridPatternXL} from 'assets/svgs/hero-pattern-xl.svg';
import {ReactComponent as GridPattern2XL} from 'assets/svgs/hero-pattern-2xl.svg';


import HeroStatic from 'assets/images/hero-static.jpg';


import Replacement from './sections/replacement'
import Calculation from './sections/calculation'
import BuiltForTeams from './sections/built-for-teams'
import KeyboardDriven from './sections/keyboard-driven'
import Presentation from './sections/presentation'
import MidPageCTA from './sections/mid-page-cta'
import BottomCTA from './sections/bottom-cta'
import Pricing from './sections/pricing'
import Hero from './sections/hero'

import styled from 'styled-components'
import { useAuthentication } from 'contexts/authentication'
import { Link } from 'react-router-dom';

const Home = () => {
  const { currentUser } = useAuthentication();
  return (
    <>
      <div className="bg-gradient-to-b from-black to-gray-900 md:from-black pb-32 md:via-gray-900 md:to-black relative overflow-hidden" >
        <GridPatternBASE className='absolute text-white block sm:hidden' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>
        <GridPatternSM className='absolute text-white hidden sm:block md:hidden' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>
        <GridPatternMD className='absolute text-white hidden md:block lg:hidden' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>
        <GridPatternLG className='absolute text-white hidden lg:block xl:hidden' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>
        <GridPatternXL className='absolute text-white hidden xl:block 2xl:hidden' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>
        <GridPattern2XL className='absolute text-white hidden 2xl:block' style={{opacity: 0.4, top: '6%', left: '0', right: 0}}/>

        <div className="relative overflow-hidden">
          <div className="block absolute inset-y-0 h-full w-full">
            <div className="relative h-full">
              <svg className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#ad9a0a02-b58e-4a1d-8c36-1b649889af63)" />
              </svg>
              <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#478e97d6-90df-4a89-8d63-30fdbb3c7e57)" />
              </svg>
            </div>
          </div>
          <Hero navComponent={<Nav/>}/>
          <div className="relative rounded-lg shadow-lg">
            <div className="absolute inset-0 flex flex-col">
              <div className="flex-1"></div>
              <div className="flex-1 w-full bg-gray-00"></div>
            </div>
            <div className="max-w-screen-xl mx-auto p-4 overflow-hidden">
              <img className="w-full mt-2 md:mt-20 sm:rounded-md md:rounded-md shadow-2xl" width="100%" src={HeroStatic} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Replacement />
      <BuiltForTeams />
      <KeyboardDriven />
      <Presentation />
      <MidPageCTA />
      <Calculation />
      <Pricing />
      <BottomCTA />
    </>
  );
}

export default Home;
