import gql from 'graphql-tag';

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      firstName
      lastName
      fullName
      authToken
      avatarUrl
      email
      username
      id
      defaultApiKey
      hasAwsIntegration
    }
  }
`;
