import React from 'react';
import { useField, ErrorMessage } from 'formik';

const Textarea = ({
  name,
  noLabel,
  label,
  placeholder,
  rows = 2
 }) => {
  const [field, { touched, error }, { setValue, setTouched, setError }] = useField(name);

  return (
    <>
      {!noLabel && <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-700 capitalize">{label || name}</label>}
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          name={name}
          placeholder={placeholder}
          rows={rows}
          onChange={(e) => setValue(e.currentTarget.value)}
          className="form-textarea py-3 px-4 block border w-full transition ease-in-out duration-150" />
      </div>
    </>
  )

}

export default Textarea
