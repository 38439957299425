import React from 'react';
import { ReactComponent as Logo } from 'assets/svgs/supergrid-white-logo-2.svg';
import { ReactComponent as LogoMobile } from 'assets/svgs/supergrid-words-stacked-white.svg';
import { NavLink } from 'react-router-dom';

const Nav = () => {

  // <NavLink activeClassName='text-gray-900' to="/case-studies" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Case Studies</NavLink>

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 py-2 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
          <div className="flex hidden md:block items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <NavLink to="/" aria-label="Home">
                <Logo className='h-10 w-auto'/>
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col md:hidden items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center flex-col justify-between w-full md:w-auto">
              <NavLink to="/" aria-label="Home">
                <LogoMobile className='h-20 w-auto'/>
              </NavLink>
            </div>
          </div>
          {/*<div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <div className="inline-flex rounded-md shadow">
              <NavLink to='/waitlist' className='text-gray-400 hover:text-indigo-400'>Join the Waitlist</NavLink>
            </div>
          </div>*/}
        </nav>
      </div>
    </>
  )
}

export default Nav
