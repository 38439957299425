import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import AuthIconButton from 'components/buttons/auth-icon-button'
import Error from 'components/typography/error'
import { ReactComponent as GoogleLogo } from 'assets/svgs/google-logo.svg';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_WITH_GOOGLE_MUTATION } from './queries';


const GoogleLoginButton = ({ onSuccess }) => {
  const [googleError, setGoogleError] = useState(null);

  const onFailure = (error) => {
    console.log('Google failure', error)
    if (error?.graphQLErrors?.[0].extensions?.code === 404) {
      setGoogleError('No account was found under that login.')
    } else {
      setGoogleError('Something went wrong, please try again.')
    }
  }


  const onOauthSuccess = async ({ tokenId }) => {
    await loginBackendWithGoogle({
      variables: {
        input: {
          googleToken: tokenId,
        },
      },
    });

  }

  const [loginBackendWithGoogle, { loading }] = useMutation(LOGIN_WITH_GOOGLE_MUTATION,
    {
      onError: onFailure,
      onCompleted: ({ loginWithGoogle }) => onSuccess(loginWithGoogle.user),
    },
  );

  return (
    <div className="flex-col">
      <GoogleLogin
        className='w-full mb-2 inline-flex flex-row justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out'
        clientId="106642059344-5s5cd0q8o2ff2ff810vt30mil8n4kuhd.apps.googleusercontent.com"
        onSuccess={onOauthSuccess}
        onFailure={onFailure}
        cookiePolicy='single_host_origin'
        prompt='consent'
        render={({ onClick, disabled }) => (
          <AuthIconButton
            onClick={onClick}
            disabled={disabled}
            icon={<GoogleLogo className="w-5 h-5 mr-3" />}
            fullWidth
            copy={loading ? 'Loading...' : 'Sign In with Google'}
          />
        )}
      />
      {googleError && <Error classes='mt-2' center>{googleError}</Error>}
    </div>
  )
}


export default GoogleLoginButton
