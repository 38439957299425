import React from 'react';

const Presentation = () => {
  return (
    <div className="bg-gray-800 py-20 py-16 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative text-center">
          <p className='text-base leading-6 text-indigo-500 tracking-wide font-semibold uppercase mb-4' style={{letterSpacing: '0.2em'}}>Introducing</p>
          <h3 className="text-center text-4xl leading-8 font-extrabold tracking-tight text-white sm:text-5xl sm:leading-10">
            Presentation Mode
          </h3>
          <p className="my-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-400">
            Effortlessly convert complex documents into persuasive reports and presentations.
          </p>
          <video className='w-full mt-8 md:mt-20 shadow-xl rounded-lg' style={{width: "100%"}} loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/presentation2.mp4">
          	<source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/presentation2.mp4" />
          </video>
        </div>
        <ul className="mt-6 w-full mr-8 grid lg:grid-cols-5 md:grid-rows-1 grid-cols-1 grid-rows-5 items-top">
          <li className="mt-6 px-2">
            <div className="flex">
              <div className="">
                <h5 className="text-lg leading-6 font-medium text-white">Full Screen Focus</h5>
                <p className="mt-2 text-lg leading-6 text-gray-400">
                  Communicate your financial model or project tracker clearly with Supergrid's read-only view.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-6 px-2">
            <div className="flex">
              <div className="">
                <h5 className="text-lg leading-6 font-medium text-white">Embed PDFs and Images</h5>
                <p className="mt-2 text-base leading-6 text-gray-400">
                  Add graphics and entire file attachments as supplements to your proposal or budget.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-6 px-2">
            <div className="flex">
              <div className="">
                <h5 className="text-lg leading-6 font-medium text-white">Host Online Dashboards</h5>
                <p className="mt-2 text-base leading-6 text-gray-400">
                  Take advantage of grids being online to quickly stand up dashboards using spreadsheet data.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-6 px-2">
            <div className="flex">
              <div className="">
                <h5 className="text-lg leading-6 font-medium text-white">Click into Nested Sheets</h5>
                <p className="mt-2 text-base leading-6 text-gray-400">
                  Hide and nest sheets to present complex documents in a clear, logical flow.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-6 px-2">
            <div className="flex">
              <div className="">
                <h5 className="text-lg leading-6 font-medium text-white">Show Clients or Investors</h5>
                <p className="mt-2 text-base leading-6 text-gray-400">
                  Invite guests to view presentation versions of your work and automatically update them with the latest changes.
                </p>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  )
}

export default Presentation
