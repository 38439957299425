import React from 'react';
import GoogleLoginButton from 'components/google-login-button';
import GithubLoginButton from 'components/github-login-button';
import { useAuthentication } from 'contexts/authentication';
import { Link } from 'react-router-dom';
import Logo from 'assets/svgs/supergrid-words-stacked-black.svg';

import H2 from 'components/typography/h2'
import A from 'components/typography/a'
import P from 'components/typography/p'
import Grid from 'components/layout/grid'
import Hr from 'components/hr'

const Login = () => {
  const { logUserIn } = useAuthentication();

  const signIn = (user) => {
    logUserIn(user);
    // window.location = `/${user.username}`;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Link to="/" aria-label="Home">
        <img className="mx-auto h-20 w-auto" src={Logo} alt="Supergrid logo" />
      </Link>
      <H2 center classes='mt-6'>Sign in to your account</H2>
      <P center classes='mt-2'>
        Or&nbsp;
        <Link to="/waitlist">
          <A href='/waitlist'>sign up for our beta</A>
        </Link>
      </P>
    </div>
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <P center>Sign in with your Google or Github account.</P>
        <Grid classes="mt-6" gap={3} columns={1}>
          <GoogleLoginButton onSuccess={signIn} />
          <Hr copy='OR'/>
          <GithubLoginButton onSuccess={signIn} />
        </Grid>
      </div>
    </div>
  </div>
  );
}

export default Login;
