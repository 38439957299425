import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { getLocalStorageEntry } from 'components/utils/local-storage';
const { REACT_APP_GRAPHQL_URL } = process.env;

const setHeaderLink = setContext(({ operationName }, { headers }) => {
  const auth = getLocalStorageEntry('flux_user_auth');

  return {
    headers: {
      ...headers,
      'X-DEEPSERVE-API-TOKEN': auth?.token,
      'Authorization': `Bearer ${auth?.token}`,
      'X-USER-ID': auth?.id,
    },
  };
});

const httpLink = new HttpLink({
  uri: REACT_APP_GRAPHQL_URL,
  credentials: 'omit',
});

const client = new ApolloClient({
  link: ApolloLink.from([setHeaderLink, httpLink]),
  cache: new InMemoryCache(),
});





export default client;
