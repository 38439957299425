import React from 'react';

import TeamPageDark from 'assets/images/homepage-dark.jpg';

const KeyboardDriven = () => {
  return (
    <div id="keyboard" className="bg-gradient-to-tr from-red-400 via-pink-500 to-yellow-500 md:pt-20 pt-8 pb-20 px-4">
      <div className="max-w-screen-xl mx-auto lg:max-w-screen-xl flex lg:flex-row-reverse flex-col">
        <div className="lg:w-1/2 w-full">
          <div className='lg:pl-20 pt-8 pb-10'>
            <span className='leading-6 text-white bg-indigo-700 font-bold px-4 py-2 rounded-md shadow-lg mx-auto text-xs tracking-widest uppercase'>Our Guiding Principle</span>

            <h4 className="text-4xl mt-4 mb-10 font-extrabold text-white tracking-tight leading-16">
              Make&nbsp;Every&nbsp;User&nbsp;a <span className='font-medium'>"</span>Power&nbsp;User<span className='font-medium'>"</span>
            </h4>
            <p className="mt-2 text-xl leading-6 font-light text-gray-200">
              Supergrid makes every action accessible with the keyboard.
            </p>
            <p className="mt-2 text-xl leading-6 font-light text-gray-200">
              Just hit Command + K to open the Command Palette and search for what you need.
            </p>
            <p className="mt-2 text-lg leading-6 text-gray-200">
              Formatting, formulas, movement, and settings are all available.
            </p>
          </div>
        </div>
        <div className="max-w-xl lg:w-1/2 w-full mt-6">
          <video className='shadow-lg rounded-md mx-auto' loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/command2.mp4">
            <source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/command2.mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default KeyboardDriven


  // <div className="relative max-w-xl mx-auto lg:max-w-screen-xl">
  //   <div className="relative flex py-20 flex-row items-top justify-between">
  //     <div className="relative w-1/3">
  //       <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
  //         Make Every User<br/>a "Power User"
  //       </h4>
  //       <p className="mt-2 text-base leading-6 text-white">
  //         Stop emaling spreadsheets back and forth. Organize your Supergrid grids in team folders and spaces.
  //       </p>
  //     </div>
  //
  //     <div className="relative w-1/2">
  //       <div className='max-w-xl'>
  //
  //       </div>
  //     </div>
  //   </div>
  //
  // </div>
