import React from 'react';

const SubmitButton = ({
  copy = 'Submit',
  isSubmitting = false
}) => (
  <span className="w-full inline-flex rounded-md shadow-sm">
    <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-500 focus:shadow-outline-indigo active:bg-indigo-500 transition ease-in-out duration-150">
      {isSubmitting ? 'Submitting...' : copy }
    </button>
  </span>
)

export default SubmitButton;
