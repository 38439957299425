import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import AuthWrapper from 'contexts/authentication';
import client from 'config/apollo'

import Login from 'screens/login';
import Waitlist from 'screens/waitlist';
import Signup from 'screens/signup';
import Home from 'marketing/home';
import BaseStyle from 'styles/base';



const App = () => {
  return (
    <>
      <BaseStyle />
      <ApolloProvider client={client}>
        <Router>
          <AuthWrapper>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/waitlist">
                <Waitlist />
              </Route>
            </Switch>
          </AuthWrapper>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;

// <Nav />
