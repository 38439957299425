import React from 'react';
import TeamPageDark from 'assets/images/homepage-dark-cropped.jpg';

const BuiltForTeams = () => {
  return (
    <div className="pt-16 md:pb-24 pb-8 bg-gradient-to-tl from-gray-200 via-white to-white overflow-hidden relative lg:py-24">

      <svg className="hidden lg:block absolute right-full transform translate-x-40 -translate-y-0" style={{top: '-450px'}}width="404" height="684" fill="none" viewBox="0 0 404 784">
        <defs>
          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>

      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 relative">

        <div className="relative mt-8 pb-20 md:pb-32 flex flex-col lg:flex-row items-top">
          <div className="relative w-full lg:w-1/3">
            <h4 className="lg:text-5xl leading-8 font-extrabold text-gray-900 tracking-tight text-4xl sm:leading-9">
              Built For Teams
            </h4>
            <p className='text-gray-600 font-light text-2xl mt-2 lg:w-3/4'>Say goodbye to email attachments like <code className='text-indigo-500 text-base align-middle'>version7-USE_THIS_ONE-final-2.xlsx</code></p>
            <div className="relative lg:hidden w-full mt-8 lg:mt-20 mt-0 lg:w-2/3">
              <img className="relative mx-auto shadow-xl rounded-lg" width="100%" src={TeamPageDark} alt="" />
              <video style={{top: '50px', right: '-50px'}} className='absolute mt-10 md:mt-0 shadow-xl rounded-lg mx-auto' loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/collab3.mp4">
              	<source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/collab3.mp4" />
              </video>
            </div>
            <ul className="mt-32 md:mt-72 lg:mt-10 w-100 grid grid-cols-1 sm:grid-cols-2 sm:gap-8 lg:grid-cols-1 lg:grid-rows-5 lg:mr-8">
              <li className="mt-3 lg:mt-0 border-b pb-3">
                <div className="flex">
                  <div className="">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Shared Team Folders</h5>
                    <p className="mt-2 text-lg leading-6 text-gray-500">
                      Organize your Supergrid grids in team folders and spaces.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3 lg:mt-0 border-b pb-3">
                <div className="flex">
                  <div className="">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Multiplayer Collaboration</h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Edit grids live with your colleagues to build projects together and speed up your workflow.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3 lg:mt-0 border-b pb-3">
                <div className="flex">
                  <div className="">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Named Versions</h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Post your changes to others, rewind to past versions, and see redlines of changes.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3 lg:mt-0 border-b pb-3">
                <div className="flex">
                  <div className="">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Comments</h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Leave feedback and ideas for others with built-in comments and notifications.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3 pb-3">
                <div className="flex">
                  <div className="">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Shared Styling</h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Use Team Styles and templates to make beautiful sheets for your brand.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="relative w-full hidden lg:block lg:pl-10 mt-20 md:mt-0 md:w-2/3">
            <img className="relative mx-auto shadow-lg rounded-md" width="100%" src={TeamPageDark} alt="" />
            <video style={{top: '220px', right: '-220px'}} className='lg:absolute mt-10 md:mt-0 shadow-lg rounded-md mx-auto' loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/collab3.mp4">
            	<source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/collab3.mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuiltForTeams
