import React, { useState } from 'react';
import { ReactComponent as GreenCheck } from 'assets/svgs/green-check.svg';

const Pricing = () => {
  const [monthly, setMonthly] = useState(true);

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">Start building for free, and upgrade when you're ready for integrations and collaborators.</p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button onClick={() => setMonthly(true)} type="button" className={`${monthly ? 'bg-white border-gray-200 shadow-sm' : 'ml-0.5 border-transparent'} relative w-1/2 rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8`}>Monthly billing</button>
            <button onClick={() => setMonthly(false)} type="button" className={`${!monthly ? 'bg-white border-gray-200 shadow-sm' : 'ml-0.5 border-transparent'} relative w-1/2 rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8`}>Yearly billing</button>
          </div>
        </div>
        <div className="mt-12 space-y-12 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900">Personal</h2>
            <p className="mt-4 text-sm text-gray-500">Free for individuals. Everything you can do in Excel and more.</p>
            <p className="mt-8">
              <span className="text-4xl font-extrabold text-gray-900">$0</span>
            </p>
            <a href="#" className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Get Started</a>
          </div>
          <div className="pt-6 pb-8 px-6">
            <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
            <ul className="mt-6 space-y-4">
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Unlimited grids</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Unlimited folders</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Command Palette</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Presentation Mode</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">1200+ Formula Functions</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Supergrid Query Language</span>
              </li>
              <li className="flex space-x-3">
                <GreenCheck />
                <span className="text-sm text-gray-500">Named References</span>
              </li>
            </ul>
          </div>
        </div>
          <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div className="p-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Personal Pro</h2>
              <p className="mt-4 text-sm text-gray-500">Expanded functionality and integrations for power users</p>
              <p className="mt-8 flex flex-row">
                <span className="text-4xl font-extrabold text-gray-900">{monthly ? '$15' : '$12'}</span>
                <span className='flex flex-col ml-2 mt-1'>
                  <span className="text-base font-base text-gray-500 leading-4">&nbsp;</span>
                  <span className="text-base font-base text-gray-500 leading-4">per month {monthly ? null : <span className='text-xs text-gray-700 tracking-tight'>(billed yearly)</span>}</span>
                </span>
              </p>
              <a href="#" className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Try Pro for Free</a>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Everthing in Personal, plus:</h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Dark Mode</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Version Tracking</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Connected Sheets</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Python Code Extensions</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">API Access</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div className="p-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Team</h2>
              <p className="mt-4 text-sm text-gray-500">For high-power teams who want to share and collaborate.</p>
              <p className="mt-8 flex flex-row">
                <span className="text-4xl font-extrabold text-gray-900">{monthly ? '$45' : '$40'}</span>
                <span className='flex flex-col ml-2 mt-1'>
                  <span className="text-base font-base text-gray-500 leading-4">per editor</span>
                  <span className="text-base font-base text-gray-500 leading-4">per month {monthly ? null : <span className='text-xs text-gray-700 tracking-tight'>(billed yearly)</span>}</span>
                </span>
              </p>
              <a href="#" className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Try Team for Free</a>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Everthing in Startup, plus:</h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">1 Shared Team Space</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">File and Folder Permissions</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Multiplayer</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Team Style Presets</span>
                </li>
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Unlimited View-Only Users</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div className="p-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Enterprise</h2>
              <p className="mt-4 text-sm text-gray-500">Fine-tuned control and support to run your company.</p>
              <p className="mt-8 flex flex-row">
                <span className="text-4xl font-extrabold text-gray-900">$200+</span>
                <span className='flex flex-col ml-2 mt-1'>
                  <span className="text-base font-base text-gray-500 leading-4">per editor</span>
                  <span className="text-base font-base text-gray-500 leading-4">per month</span>
                </span>
              </p>
              <a href="#" className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Contact Us</a>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Everthing in Pro, plus:</h3>
              <ul className="mt-6 space-y-4">
                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Unlimited Team Spaces</span>
                </li>

                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Support SLAs</span>
                </li>

                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Audit Logs</span>
                </li>

                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Custom Integration Building</span>
                </li>

                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">SAML SSO</span>
                </li>

                <li className="flex space-x-3">
                  <GreenCheck />
                  <span className="text-sm text-gray-500">Link Sharing Analytics</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
