import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom'

import { GET_CURRENT_USER_QUERY } from './queries.js';
import { getLocalStorageEntry, setLocalStorageEntry, removeLocalStorageEntry } from 'components/utils/local-storage';

const LOCALSTORAGE_KEY = 'flux_user_auth';

const AuthenticationContext = createContext({ user: null });

const AuthWrapper = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const history = useHistory();

  const client = useApolloClient();

  const logUserIn = useCallback((user) => {
    console.log('logUserIn', user)
    setLocalStorageEntry(LOCALSTORAGE_KEY, {
      token: user.authToken,
      id: user.id
    });
    console.log('logUserInSuccess');
    setCurrentUser(user);
    console.log('setCurrentUser success');
  }, []);


  const logUserOut = useCallback(() => {
    setCurrentUser(null);
    removeLocalStorageEntry(LOCALSTORAGE_KEY);
    window.location = '/';
  }, []);


  const authAndLoadUser = async () => {
    const userData = getLocalStorageEntry(LOCALSTORAGE_KEY);

    if (!userData) {
      return;
    }

    const currentUserResponse = await client.query({ query: GET_CURRENT_USER_QUERY });
    const currentUser = currentUserResponse?.data?.currentUser;

    if (!currentUser) {
      return;
    }

    setCurrentUser(currentUser)
  }


  useEffect(() => {
    authAndLoadUser()
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        logUserIn,
        logUserOut
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('AuthWrapper is missing');
  }
  return context;
};

export { AuthenticationContext, useAuthentication };
export default AuthWrapper;
