import React from 'react';

import NamedReferences from 'assets/images/named-references.jpg';
import Sgql from 'assets/images/sgql.jpg';
import Dependencies from 'assets/images/dependencies.jpg';
import ConnectedSheets from 'assets/images/connected-sheets.jpg';



const Presentation = () => {
  return (
    <div className="bg-gradient-to-br from-indigo-800 via-purple-700 to-pink-800 py-20 py-16 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-2xl">
        <div className="relative text-center">
          <h3 className="text-center text-4xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
            So Much More Than&nbsp;Formulas
          </h3>
          <p className="my-4 mx-auto text-center text-xl leading-7 font-light tracking-wide text-gray-200">
            Supergrid invented a new computation engine to change the way you interact with spreadsheets.
          </p>
        </div>
        <div className="mt-10 grid lg:grid-cols-3 lg:grid-rows-1 grid-cols-1 grid-rows-3 items-top">
          <div className='px-8 py-4 md:px-12 md:py-12'>
            <img className="mb-8 mx-auto shadow-lg rounded-md" width="100%" src={NamedReferences} alt="" />
            <h5 className='text-white text-lg font-bold mb-2'>Named References</h5>
            <p className='text-gray-300 leading-6'>Give names to rows, columns, and cells to create variables and make formulas comprehensible.</p>
          </div>
          <div className='px-8 py-4 md:px-12 md:py-12'>
            <img className="mb-8 mx-auto shadow-lg rounded-md" width="100%" src={Sgql} alt="" />
            <h5 className='text-white text-lg font-bold mb-2'>SuperGrid Query Language</h5>
            <p className='text-gray-300 leading-6'>Perform complex filtering, grouping and aggregation easier than with pivot tables using a subset of SQL.</p>
          </div>
          <div className='px-8 py-4 md:px-12 md:py-12'>
            <img className="mb-8 mx-auto shadow-lg rounded-md" width="100%" src={Dependencies} alt="" />
            <h5 className='text-white text-lg font-bold mb-2'>Intelligent Recalculation</h5>
            <p className='text-gray-300 leading-6'>Supergrid keeps track to only recalculate the cells it has to and doesn't slow down — so you never have to wait.</p>
          </div>
        </div>
        <div className='flex flex-col md:flex-row-reverse mt-32 max-w-screen-2xl'>
          <div className='w-full lg:w-1/3'>
            <h4 className="lg:pl-16 mt-2 text-left leading-8 font-bold text-white text-4xl sm:leading-10">
              Connected Sheets
            </h4>
            <p className='lg:pl-16 mt-4 text-gray-100 leading-7 font-light tracking-wide text-2xl'>Automatically pull in external data to use just like a normal sheet.</p>
            <div className='w-full lg:hidden mt-10'>
              <img className="md:mb-8 mb-2 mx-auto shadow-xl rounded-md" width="100%" src={ConnectedSheets} alt="" />
            </div>
            <p className='lg:pl-16 mt-12 text-gray-300 leading-6 text-md'>Connect to other supergrids, Google Sheets, or even SQL databases with millions of rows.</p>
            <p className='lg:pl-16 mt-6 text-gray-300 leading-6 text-md'>Browse popular plugins like Google Analytics and Facebook to automatically pull reports.</p>
            <p className='lg:pl-16 mt-6 text-gray-300 leading-6 text-md'>Use external sheets with millions of rows and experience no lag.</p>
            <p className='lg:pl-16 mt-6 text-gray-300 leading-6 text-md'>Supergrid only renders the cells you need, but allows you to perform calculations on the entire dataset.</p>
          </div>
          <div className='w-2/3 hidden lg:block'>
            <img className="mb-8 mx-auto shadow-xl rounded-md" width="100%" src={ConnectedSheets} alt="" />
          </div>
        </div>
        <div className='flex flex-col lg:flex-row mt-40 max-w-screen-2xl'>
          <div className='w-full lg:w-1/4 lg:pr-12'>
            <h4 className="text-left mt-4 font-bold text-white text-4xl sm:leading-12">
              Code Extensions
            </h4>
            <p className='mt-4 text-gray-100 leading-7 font-light tracking-wide text-2xl'>Write Python, Do Anything.</p>
            <div className='w-full block lg:hidden mt-10'>
              <video className='shadow-xl rounded-lg' style={{width: "100%"}} loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/python2.mp4">
              <source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/python2.mp4" />
              </video>
            </div>
            <p className='mt-12 text-gray-300 leading-6 text-md'>Interact with your data with Python to compute complex formulas programatically.</p>
            <p className='mt-6 text-gray-300 leading-6 text-md'>Use the results in formulas just like any other sheet.</p>
            <p className='mt-6 text-gray-300 leading-6 text-md'>Connect with external plugins and APIs — pull fresh data, post changes to a database, or notify a webhook.</p>
            <p className='mt-6 text-gray-300 leading-6 text-md'>Embed buttons in your sheets to create no-code solutions for your unique workflows.</p>
            <p className='mt-6 text-gray-300 leading-6 text-md'><b>Look closely at the example on the right.</b> This code prints a template sheet to PDF, emails it, notifies a webhook, and returns cleaned up data into the sheet.</p>

          </div>
          <div className='lg:w-3/4 lg:block hidden'>
            <video className='shadow-xl rounded-lg' style={{width: "100%"}} loop autoPlay playsInline muted src="https://supergrid-website-videos.s3.amazonaws.com/python2.mp4">
            <source type="video/mp4" src="https://supergrid-website-videos.s3.amazonaws.com/python2.mp4" />
            </video>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Presentation
