import React from 'react';
import WaitlistSignup from 'marketing/components/waitlist-signup';

const Hero = ({ navComponent }) => {
  return (
    <div id="hero" className="relative pt-6 pb-12 lg:pb-20">
      {navComponent}
      <div className="mt-20 mx-auto max-w-screen-xl md:mt-16 lg:mt-28">
        <div className="text-center">
          <p className='text-sm md:text-base leading-6 text-indigo-500 font-normal uppercase mb-4' style={{letterSpacing: '0.5em'}}>This is Supergrid</p>
          <h2 className="text-4xl px-4 md:text-6xl tracking-tight leading-16 md:leading-16 font-bold text-white sm:leading-none">
            Recalculate Your&nbsp;Expectations
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base font-light tracking-wide text-gray-400 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            The next era of spreadsheets, engineered for speed&nbsp;and&nbsp;collaboration
          </p>
          <WaitlistSignup />
        </div>
      </div>
    </div>
  )
}

export default Hero
