import gql from 'graphql-tag';

export const LOGIN_WITH_GITHUB_MUTATION = gql`
  mutation LoginWithGithub($input: LoginWithGithubInput!) {
    loginWithGithub(input: $input) {
      user {
        firstName
        lastName
        fullName
        authToken
        email
        username
        id
      }
    }
  }
`;
